@font-face {
  font-family: "Mada";
  src: url("../assets/fonts/Mada-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Mada";
  src: url("../assets/fonts/Mada-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Mada";
  src: url("../assets/fonts/Mada-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Mada";
  src: url("../assets/fonts/Mada-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Mada";
  src: url("../assets/fonts/Mada-Regular.ttf");
  font-weight: 400;
}
