@import url("./_fonts.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .hidden-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .hidden-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #d2d2d2;
    border-radius: 4px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #848484;
  }
}

@layer base {
  .container {
    @apply mx-auto px-4;
  }

  @media (min-width: 640px) {
    .container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .container {
      max-width: 1400px !important;
    }
  }
}

body {
  font-family: "Mada", sans-serif;
  font-weight: 400;
  background-color: #f8f8f8;
}

ul {
  padding-left: 8px;
  margin-top: 16px;
}

li {
  margin-bottom: 14px;
  position: relative;
  font-weight: 400;
  padding-left: 12px;
}

li::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: -7px;
  @apply bg-primary;
}
