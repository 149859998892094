.topbarLink {
  @apply text-black text-base font-medium hover:text-primary relative;
}

.topbarLink::before {
  @apply absolute w-2 h-2 bg-primary rounded-full top-1/2 -translate-y-1/2 lg:top-6 lg:left-1/2 -left-4 lg:-translate-x-1/2 lg:translate-y-0;
}

.topbarLink:global(.active) {
  @apply text-primary;
}

.topbarLink:global(.active)::before {
  @apply content-[''];
}
